import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

type PrizeDropsImmediate = { immediate?: boolean };

const useAppPrizeDrops = ({ immediate }: PrizeDropsImmediate = { immediate: true }) => {
	const { t } = useT();
	const { open } = useAppModals();
	const loginGuard = useLoginGuard();
	const isGuest = useIsGuest();

	const { data: prizeDropsData } = useGetPrizeDropsInfoData({ immediate });

	const timer = computed(() => dayjs(prizeDropsData.value?.general?.finishedAt, "YYYY-MM-DDTHH:mm:ss.000ZZ").format());

	const nextPrizeTime = computed(() => prizeDropsData.value?.nextPrizeWillAccrueIn);

	const { durationLeft, duration } = useCountdown({
		timestamp: convertDateTimeFormat(prizeDropsData.value?.general?.finishedAt || ""),
		formatToken: dayjs(timer.value).diff(dayjs(), "d") >= 1 ? "DD[d] HH[h] mm[m]" : "HH[H ]mm[M ]",
		isOptimized: true
	});

	const {
		durationLeft: toNextPrizeDurationLeft,
		duration: toNextPrizeDuration,
		reset
	} = useCountdown({
		timestamp: secondsToDate(prizeDropsData.value?.nextPrizeWillAccrueIn),
		formatToken: "ss",
		isOptimized: false
	});

	const resetPrizeDropTimer = (value: number) => {
		reset(secondsToDate(value));
	};

	const lastWinners = computed(() => prizeDropsData.value?.lastWinners);

	const buttonText = computed(() => {
		if (isGuest.value) {
			return t("Create an Account");
		}
		return t("Start Playing");
	});

	const handleCardClick = () => {
		loginGuard({
			success: () => {
				navigateTo("/prize-drops");
			},
			fail: () => open("LazyOModalSignup")
		});
	};

	return {
		duration,
		buttonText,
		durationLeft,
		lastWinners,
		prizeDropsData,
		nextPrizeTime,
		handleCardClick,
		toNextPrizeDurationLeft,
		toNextPrizeDuration,
		resetPrizeDropTimer
	};
};

export default useAppPrizeDrops;
