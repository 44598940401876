import dayjs from "dayjs";

import type { EventData, PromotionsResponse, Promotion } from "@/types";

const selectPrizeEntries = (promotion: Promotion) => {
	if (promotion.type === "quest") {
		return promotion.data?.grandPrize?.entries;
	}
	return promotion.data?.prizeEntries;
};

const selectPrizeCoins = (promotion: Promotion) => {
	if (promotion.type === "quest") {
		return promotion.data?.grandPrize?.coins;
	}
	return promotion.data?.prizeCoins;
};

const selectPrizeUsualPrice = (promotion: Promotion) => {
	if (!promotion.data?.promoOfferPreset?.usualPrice) {
		return;
	}
	return Number(promotion.data?.promoOfferPreset?.usualPrice).toFixed(2);
};

const selectBadgeLabel = (promotion: Promotion) => parseJSON(promotion.data?.promoOfferPreset?.badgeLabel || "{}");

const selectPackage = ({
	entries,
	coins,
	promotion,
	howWorks
}: {
	entries: number;
	coins: number;
	howWorks: string;
	promotion: Promotion;
}) => {
	const packages = {
		tournament: {
			title: "",
			entries,
			coins,
			type: "tournament",
			imagePopup: "",
			imagePopupMobile: "",
			subTitle: "",
			howWorks: ""
		},
		offerDeposit: {
			title: "",
			entries: 0,
			coins: 0,
			type: "offerDeposit",
			imagePopup: "",
			imagePopupMobile: "",
			subTitle: "",
			howWorks: ""
		},
		offerPeriodic: {
			title: "",
			entries: 0,
			coins: 0,
			type: "offerDeposit",
			imagePopup: "",
			imagePopupMobile: "",
			subTitle: "",
			howWorks: ""
		},
		invite: {
			title: "",
			entries,
			coins,
			type: "invite",
			imagePopup: "",
			imagePopupMobile: "",
			subTitle: "",
			howWorks
		}
	};
	if (promotion.data?.promoOfferPreset) {
		return promotion.data?.promoOfferPreset;
	}
	const packageType = promotion.type as keyof typeof packages;
	if (!(packageType in packages)) {
		return;
	}
	return packages[packageType];
};

const selectHowWorks = (item: ReturnType<typeof selectPackage> | undefined) => {
	if (item === undefined || item.howWorks === undefined) {
		return item;
	}
	return {
		...item,
		howWorks: parseJSON<Record<string, string | number>>(item?.howWorks || "{}")
	};
};

const hasPromotionInfoMap = {
	verification: true,
	inviteQualification: true,
	magicBox: true,
	quest: true
} as const;

const selectHasPromotionInfo = (promotion: Promotion) => {
	const type = promotion.type || "";
	return type in hasPromotionInfoMap;
};

const selectTooltipText = ({ t, promotion }: { t: ReturnType<typeof useT>["t"]; promotion: Promotion }) => {
	const daysOfWeek = promotion.data?.promoOfferPreset?.dayOfWeek || [];
	const hasOneDay = daysOfWeek.length === 1;
	if (promotion.data?.needDeposits) {
		if (promotion.data?.needDeposits === 1) {
			return t("Available after the st purchase", { msg: promotion.data?.needDeposits });
		}
		return t("Available after the nd purchase", { msg: promotion.data?.needDeposits });
	}
	if (promotion.data?.promoOfferPreset?.isLimitPerDayReason) {
		if (!hasOneDay) {
			return t("Can be claimed again next weekends.");
		}
		const index = parseInt(daysOfWeek[0]);
		return t("Available after the", { days: dayjs().day(index).format("dddd") });
	}
	if (promotion.data?.promoOfferPreset?.isMinBalanceReason) {
		return t("Can be claimed again when your total entries + winnings + pending redeems are less than.", {
			msg: promotion.data?.promoOfferPreset.minBalance
		});
	}
	if (hasOneDay) {
		const index = parseInt(daysOfWeek[0]);
		return t("Available on", { days: dayjs().day(index).format("dddd") });
	}
	return t("Available on weekends");
};

const selectDate = (promotion: Promotion) =>
	dayjs().add(
		promotion.data?.promoOfferPreset?.availableTill ? Number(promotion.data?.promoOfferPreset?.availableTill) : 0,
		"second"
	);

const createDecorator = (t: ReturnType<typeof useT>["t"]) => (promotion: Promotion) => {
	const prizeEntries = selectPrizeEntries(toRaw(promotion));
	const prizeCoins = selectPrizeCoins(toRaw(promotion));
	const usualPrice = selectPrizeUsualPrice(toRaw(promotion));
	const badgeLabel = selectBadgeLabel(toRaw(promotion));

	const pkg = selectHowWorks(
		selectPackage({
			entries: prizeEntries || 0,
			coins: prizeCoins || 0,
			howWorks: promotion.data?.howWorks || "{}",
			promotion
		})
	);

	const date = selectDate(promotion);
	const tooltipText = selectTooltipText({ t, promotion });
	const hasPromotionInfo = selectHasPromotionInfo(promotion);
	return {
		...promotion,
		package: pkg,
		usualPrice,
		prizeEntries,
		prizeCoins,
		hasPromotionInfo,
		badgeLabel,
		date,
		tooltipText
	};
};

const normalize = (promotions: Array<Promotion>, packageDecorator: ReturnType<typeof createDecorator>) =>
	promotions.reduce(
		(accumulator: Array<Promotion>, promotion) => [...accumulator, packageDecorator(promotion)],
		[]
	) as Array<ReturnType<typeof packageDecorator>>;

const transform = (packageDecorator: ReturnType<typeof createDecorator>) => (data: PromotionsResponse) => {
	const promotions = normalize(data.payload!.promotions! as Array<Promotion>, packageDecorator);
	const offerBanner = promotions.find(({ type }) => type === "offerDeposit");
	const offer = offerBanner?.data?.promoOfferPreset;
	const availableSoon = normalize(data.payload!.available_soon as Array<Promotion>, packageDecorator);
	return {
		promotions,
		offerBanner,
		offer,
		availableSoon
	};
};
const defaultData = (): ReturnType<ReturnType<typeof transform>> => ({
	promotions: Array(3).fill({
		skeleton: true
	}),
	offerBanner: {
		type: "offerDeposit",
		skeleton: true,
		prizeCoins: 0,
		prizeEntries: 0,
		usualPrice: "0",
		package: undefined,
		hasPromotionInfo: false,
		date: dayjs(),
		tooltipText: "",
		badgeLabel: {}
	},
	offer: {},
	availableSoon: Array(4).fill({
		skeleton: true
	})
});

const usePromotionsData = () => {
	const { t } = useT();
	const { data: appInit } = useAppInitData();
	const { pageData, refresh: refreshHomeData } = useHomePage();
	const { add } = useSockets();

	const openDeposit = (promotion: Promotion) => {
		let url = "";

		if (promotion?.data?.promoOfferPreset?.imagePopup) {
			url = promotion?.data?.promoOfferPreset
				? `/cash/deposit-by-money/${promotion?.data?.promoOfferPreset.id}/promoOffer/?inviteQualification=true`
				: `/cash/deposit-by-money/${promotion?.data?.preset?.id}/preset/?inviteQualification=true`;
		} else {
			url = "/cash/deposit-by-money/";
		}

		window?.$cash?.$router?.push?.(url);
	};

	const openCash = (promotion: Promotion) => {
		window?.$cash?.$router?.push?.(`/cash/deposit-by-money/${promotion?.data?.promoOfferPreset?.id}/promoOffer/`);
	};

	const homePromotions = computed(() => ({
		promotions: pageData.value?.promotions?.map((el) => createDecorator(t)(el as Promotion))
	}));

	const { data: promoData, refresh } = useAsyncFetch({
		path: "/rest/page/promotions/",
		method: "get",
		options: {
			server: false,
			immediate: !appInit.value?.isGuest
		}
	});

	const promotionsData = computed(() => {
		if (!promoData.value) {
			return defaultData();
		}

		return transform(createDecorator(t))(promoData.value);
	});

	add("promotionsPage", ({ action }: EventData<Promotion>) => {
		if (action === "update") {
			refresh();
			refreshHomeData();
		}
	});

	return {
		refresh,
		promotionsData,
		homePromotions,
		openDeposit,
		openCash
	};
};

export default usePromotionsData;
