import revive_payload_client_Ys8UsOyt2b from "/app/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@20.11.26_eslint@8.57.0_ioredis@5.4.1_magicast@0_zkk276uxeti5aes6foyxg5ll4i/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_BtXK97Lhuo from "/app/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@20.11.26_eslint@8.57.0_ioredis@5.4.1_magicast@0_zkk276uxeti5aes6foyxg5ll4i/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_mhsnDahkBK from "/app/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@20.11.26_eslint@8.57.0_ioredis@5.4.1_magicast@0_zkk276uxeti5aes6foyxg5ll4i/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_78ekErnWvM from "/app/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@20.11.26_eslint@8.57.0_ioredis@5.4.1_magicast@0_zkk276uxeti5aes6foyxg5ll4i/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_yTww9A27kj from "/app/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@20.11.26_eslint@8.57.0_ioredis@5.4.1_magicast@0_zkk276uxeti5aes6foyxg5ll4i/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/app/apps/fortunewheelz/.nuxt/components.plugin.mjs";
import prefetch_client_QU9vh2akWs from "/app/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@20.11.26_eslint@8.57.0_ioredis@5.4.1_magicast@0_zkk276uxeti5aes6foyxg5ll4i/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_P5GUQHDVZT from "/app/node_modules/.pnpm/@dargmuesli+nuxt-cookie-control@8.3.2_magicast@0.3.4_rollup@4.18.1_webpack@5.93.0/node_modules/@dargmuesli/nuxt-cookie-control/dist/runtime/plugin.mjs";
import i18n_UXbKdu5ZIJ from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.1.1_magicast@0.3.4_rollup@4.18.1_vue@3.4.31_typescript@5.4.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_yc1zPNrDgC from "/app/node_modules/.pnpm/@nuxtjs+device@3.1.0_magicast@0.3.4_rollup@4.18.1/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import chunk_reload_client_5aWsyZ6rjN from "/app/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@20.11.26_eslint@8.57.0_ioredis@5.4.1_magicast@0_zkk276uxeti5aes6foyxg5ll4i/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _01_raygun_client_U5BKN7hadT from "/app/node_modules/.pnpm/@netgame+layer-raygun@1.6.7_magicast@0.3.4_rollup@4.18.1/node_modules/@netgame/layer-raygun/plugins/01.raygun.client.ts";
import cashHashRedirector_Q96kf6aEuS from "/app/apps/fortunewheelz/plugins/cashHashRedirector.ts";
import mask_Tk3SUcMqzt from "/app/apps/fortunewheelz/plugins/mask.ts";
import vue3_toastify_OGYNDsiW9E from "/app/apps/fortunewheelz/plugins/vue3-toastify.ts";
export default [
  revive_payload_client_Ys8UsOyt2b,
  unhead_BtXK97Lhuo,
  router_mhsnDahkBK,
  payload_client_78ekErnWvM,
  check_outdated_build_client_yTww9A27kj,
  components_plugin_KR1HBZs4kY,
  prefetch_client_QU9vh2akWs,
  plugin_P5GUQHDVZT,
  i18n_UXbKdu5ZIJ,
  plugin_yc1zPNrDgC,
  chunk_reload_client_5aWsyZ6rjN,
  _01_raygun_client_U5BKN7hadT,
  cashHashRedirector_Q96kf6aEuS,
  mask_Tk3SUcMqzt,
  vue3_toastify_OGYNDsiW9E
]