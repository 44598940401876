const usePresetPackageInit = () => {
	const { data } = useAppInitData();
	const route = useRoute();
	const loginGuard = useLoginGuard();
	const guardFunction = usePopupPresetPackageGuard();
	const { state } = usePopupsState();
	const { openDefault, openAuth, openLoginSocial } = usePresetPackageController();
	const { query, hash } = route;
	const { open } = useAppModals();

	const hasDirectLink = computed(() => !!query.game || hash.includes("/cash") || !!query.openSupport);

	watch(
		() => data.value?.isGuest,
		(value, oldValue) => {
			if (!value && oldValue) {
				setTimeout(() => {
					if (data.value?.depositStreak?.isActive) {
						open("LazyOModalWheelOfTreasureWelcome");
					}
					if (data.value?.magicBox?.isActive) {
						open("LazyOModalMagicBoxesWelcome");
					}
				}, 2000);
				openLoginSocial();
			}
		}
	);

	watch(
		() => route.query,
		(value, oldValue) => {
			loginGuard({
				success: () => {
					if (oldValue.social === "login") {
						openDefault();
					}
				}
			});
		}
	);

	const initPopup = () => {
		const redirectToPackagePopup = route?.query?.redirectUrl === "bannerPresetPackage";
		const openModalPreset = route?.query?.openModal === "app-banner-preset-package-modal";

		openLoginSocial();

		if (!hasDirectLink.value && !state.value.initPopupState) {
			state.value.initPopupState = true;
		}

		if (redirectToPackagePopup) {
			openAuth();
			return;
		}

		if (openModalPreset) {
			openDefault();
		}
	};

	onMounted(() => {
		guardFunction({
			success: () => {
				if (route?.path?.includes("game") || route?.query?.game || route?.hash?.includes("cash")) {
					return;
				}

				initPopup();
			}
		});
	});
};

export default usePresetPackageInit;
