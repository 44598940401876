<script lang="ts" setup>
import { toast } from "vue3-toastify";

import type { DetailInvite } from "@/types";

const route = useRoute();
const { open, close } = useAppModals();
const loginGuard = useLoginGuard();
const { data: appInitData } = useAppInitData();
const { refresh: refreshWheelOfTreasure } = useDepositStreakData({ immediate: false });
const { refresh: refreshScratchCards } = useScretchCardData({ immediate: false });
const { checkDailyOpen } = useWelcomeModal(open, close);
const { t } = useT();
const { add } = useSockets();
const { checkPendingBonus } = useWithoutDepositBonus(open, t);
const { sendAdTrigger } = usePopupAds();
const popUpAds = useState("popUpAds");
const { resetPrizeDropTimer, lastWinners, duration, toNextPrizeDurationLeft, toNextPrizeDuration } = useAppPrizeDrops();

provide("prizeDrop", {
	resetPrizeDropTimer,
	lastWinners,
	duration,
	toNextPrizeDurationLeft,
	toNextPrizeDuration
});

useCheckPromoOffer(open);
useAppRefreshSocket(t);
useInitRegisterComplete();
usePresetPackageInit();
useSeasonsSocket();
useRaceSockets({ sliceRange: 4, showFinishedToast: false, t, openModal: open });

onMounted(() => {
	const { showTimeToast } = usePrizeDropsToasts();
	usePrizeDropSocket({ t, open, showTimeToast, toNextPrizeDurationLeft, resetTimer: resetPrizeDropTimer });
	if (appInitData.value?.captcha && appInitData.value?.captcha?.isEnabled && appInitData.value?.captcha?.key) {
		useHead({
			script: [
				{
					src: appInitData.value?.captcha?.url,
					id: appInitData.value?.captcha?.key,
					async: true,
					defer: true
				}
			]
		});
	}

	if (route.query.game) {
		open("LazyOModalGame");
	}

	loginGuard({
		success: () => {
			if (route.query?.freeEntriesBonus) {
				checkPendingBonus(route.query?.freeEntriesBonus as string);
			}
			if (route.query?.openModal?.includes("daily-wheel")) {
				open("LazyOModalDailyWheel");
			}
			if (route.query?.openModal === "wheel-of-treasure") {
				open("LazyOModalWheelOfTreasureWelcome");
			}
			if (route.query?.openModal?.includes("independence-quest")) {
				open("LazyOModalSeasonCards");
			}
			if (appInitData.value?.depositStreak?.isActive) {
				checkDailyOpen({ storage: "showWelcomeWheelOfTreasure", modal: "LazyOModalWheelOfTreasureWelcome" });
			}
			if (appInitData.value?.magicBox?.isActive) {
				checkDailyOpen({ storage: "showWelcomeMagicBoxes", modal: "LazyOModalMagicBoxesWelcome" });
			}
		}
	});
});

useUploadDocumentsToast(
	"dark",
	"/nuxt-img/account/doc-notification-confirmed.png",
	"/nuxt-img/account/doc-notification-rejected.png"
);

useEvent(["nuxt:openWheelOfTreasureHowItWorksModal"], () => {
	open("LazyOModalWheelOfTreasureHowItWorks");
});
useEvent(["nuxt:openWheelOfTreasureModal"], () => {
	open("LazyOModalWheelOfTreasurePlayWheel");
});
useEvent(["nuxt:wheelOfTreasureRefresh"], () => {
	refreshWheelOfTreasure();
});
useEvent(["nuxt:openScratchCardsTicketPlayModal"], () => {
	open("LazyOModalScratchCardsTicketPlay");
});
useEvent(["nuxt:openMagicBoxesPlayModal"], () => {
	open("LazyOModalMagicBoxesSelection");
});
useEvent(["nuxt:scratchCardsRefresh"], () => {
	refreshScratchCards();
});
useEvent(["nuxt:closeCashbox"], () => {
	sendAdTrigger("closeCashbox");
});

const showToast = (titleText: string, detail: DetailInvite) => {
	toast.success(
		`
			<div class="toast-invite-title">${titleText}</div>
			<div class="toast-invite-content">${t("You earned")}
				<span class="text-caracas bold">${detail.coins ? numberFormat(Number(detail.coins)) : ""}</span>
				<span>${detail.coins ? t("Coins") : ""}</span>
				<span class="text-chanda bold">${detail.entries ? numberFormat(Number(detail.entries)) : ""}</span>
				<span>${detail.entries ? t("Free Entries") : ""}.</span>
			</div>
		`,
		{
			toastId: detail.action,
			icon: false,
			dangerouslyHTMLString: true,
			theme: toast.THEME.DARK,
			position: toast.POSITION.BOTTOM_CENTER,
			transition: toast.TRANSITIONS.SLIDE,
			autoClose: 5000
		}
	);
};

add("invite", (detail: DetailInvite) => {
	if (detail.action === "friend-qualified") {
		showToast(t("Your friend get qualified!"), detail);
	}
	if (detail.action === "player-qualified") {
		showToast(t("You get qualified!"), detail);
	}
});

add("popUpAds", (detail: EventData<PopUpAdData[]>) => {
	if (detail?.data?.length) {
		popUpAds.value = detail.data.sort((modalA, modalB) => modalA.popup_priority - modalB.popup_priority);
	}
});
</script>
<template>
	<div>
		<NuxtLayout>
			<NuxtPage />
			<ClientOnly>
				<LazySupportChat />
				<LazyOModals />
			</ClientOnly>
		</NuxtLayout>
	</div>
</template>
<style lang="scss">
.Toastify {
	&__toast {
		overflow: visible;
		@include media-breakpoint-down(lg) {
			margin-bottom: 8px;
		}
	}
	&__toast-container {
		@include media-breakpoint-down(lg) {
			width: calc(100% - 32px);
			left: 50%;
			transform: translateX(-50%);
		}
		#friend-qualified,
		#player-qualified {
			width: 344px;
			max-width: 100%;
			margin: gutter(1) auto;

			@include media-breakpoint-down(lg) {
				width: auto;
			}
		}

		#promo-code-success {
			.Toastify__toast-icon {
				display: none;
			}
		}
	}

	.badge {
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		top: -5px;
		left: -5px;
		width: 20px;
		height: 20px;
		border-radius: gutter(0.5);
		color: var(--chattogram);
		font-size: 12px;
		background-color: var(--caracas);
		font-weight: 500;
		z-index: 100;
	}

	.icon-goal {
		display: inline-flex;
		width: 64px;
		height: 64px;
		background-image: url(/assets/icons/64/goal.svg);
		background-position: center;
		background-repeat: no-repeat;
	}

	.icon-checked {
		background-image: url(/assets/20/checked-line.svg);
	}
}

#add {
	.icon-favorite {
		background-image: url(/assets/20/favorite-add.svg);
	}
}

#remove {
	.icon-favorite {
		background-image: url(/assets/20/favorite-remove.svg);
	}
}

#notification-prize-drops {
	right: 16px;
	left: auto;
	transform: translateX(0);

	@include media-breakpoint-down(lg) {
		right: auto;
		left: 50%;
		transform: translateX(-50%);
	}

	.Toastify {
		&__toast {
			padding: 12px;
			border: 2px solid var(--chandigarh);
			background: var(--craiova);

			&-body {
				white-space: normal;
			}
			&-icon {
				width: 40px;
			}
		}
		&__close-button {
			display: block;
			min-width: 20px;
			min-height: 20px;
			position: relative;
			border-radius: 50%;
			background: rgba(var(--cesena-rgb), 0.2);
			opacity: 1;

			svg {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(calc(-50% + 0.5px), -50%);
			}
		}
	}

	.text {
		color: var(--cairo);
		font-weight: 400;
		font-size: 14px;
		line-height: 16px;
	}
}

#friend-qualified,
#player-qualified {
	.toast-invite-title {
		margin-bottom: 8px;
		font-weight: 600;
		font-size: 16px;
		line-height: 18px;
	}
	.toast-invite-content {
		max-width: 196px;
	}
	.Toastify {
		&__close-button {
			display: block;
			opacity: 1;
		}
		&__toast-body {
			white-space: normal;
		}
	}

	.bold {
		font-weight: 600;
	}
}

#upload-document-notification,
#upload-document-cash-notification {
	.Toastify {
		&__toast-icon {
			margin: 0;
			width: auto;

			.icon-close {
				visibility: hidden;
				width: 0;
			}
		}
	}

	.Toastify__toast {
		min-width: 328px;
		width: 328px;
		top: 90px;
		height: auto;
		min-height: 64px;
		max-height: 76px;
		padding: 12px;
		background-color: var(--craiova);
		border-radius: 12px;
		border: 2px solid var(--chandigarh);
		box-shadow: 0px 30px 40px -10px var(--carrollton);

		@media (max-width: 1024px) {
			width: 328px;
		}

		@media only screen and (max-width: 480px) {
			top: 110px;
		}

		.Toastify__toast-body {
			white-space: normal;
			margin: 0;
		}

		.Toastify__close-button {
			display: block;
			flex-shrink: 0;
			width: 20px;
			height: 20px;
			background: url("~/assets/docs/close.svg") center/cover no-repeat;
		}

		.Toastify__close-button > svg {
			height: 10px;
			width: 21px;
		}

		.toast-content-container {
			display: flex;
			flex-direction: row;
			align-items: center;
		}

		.toast-img-container {
			display: flex;
			align-items: center;
		}

		.toast-img {
			width: 40px;
			height: 40px;
		}

		.toast-text {
			padding: 0 8px;
			line-break: auto;
			white-space: pre-line;
		}

		.toast-title {
			font-size: 14px;
			font-weight: 600;
			line-height: 16px;
			color: var(--cesena);
		}

		.toast-message {
			color: var(--cairo);
			font-size: 14px;
			font-weight: 400;
			line-height: 16px;
			padding-top: 4px;
		}
	}
}

#upload-document-cash-notification {
	.Toastify__toast {
		top: 0px;

		@media only screen and (max-width: 480px) {
			top: 20px;
		}
	}
}

.grecaptcha-badge {
	visibility: hidden;
}
</style>
