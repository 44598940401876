import { toast } from "vue3-toastify";

import OPrizeDropsTimeToast from "@/organizms/O/PrizeDrops/TimeToast.vue";

const usePrizeDropsToasts = () => {
	const { t } = useT();
	const { isUserInGame } = useGameModal();

	const toastId = ref();
	const intervalId = ref();

	if (toast.isActive(toastId.value)) {
		toast.remove(toastId.value);
	}

	const showTimeToast = (initialTime: number) => {
		const toastProps = reactive({
			initialDuration: initialTime,
			title: t("prizeDrops.toastWithTimer.title"),
			text: t("prizeDrops.toastWithTimer.text"),
			btnText: t("prizeDrops.toastWithTimer.btn"),
			timerText: t("prizeDrops.toast.timeLeft")
		});

		clearInterval(intervalId.value);
		toastProps.initialDuration = initialTime;

		intervalId.value = setInterval(() => {
			if (toastProps.initialDuration === 0) {
				clearInterval(intervalId.value);
				return;
			}
			if (toastProps.initialDuration > 0) {
				toastProps.initialDuration -= 1;

				if (toastProps.initialDuration === 40 && !isUserInGame.value) {
					toastId.value = toast.success(h(OPrizeDropsTimeToast, toastProps), {
						containerId: "prize-receive-timer",
						icon: h("img", {
							src: "/nuxt-img/prize-drops/toast-icon.png",
							width: 40,
							height: 40
						}),
						theme: toast.THEME.DARK,
						position: toast.POSITION.TOP_RIGHT,
						transition: toast.TRANSITIONS.SLIDE,
						autoClose: 5000,
						onClick: () => {
							navigateTo("/issues/popular-games/");
						}
					});
				}
				return;
			}

			clearInterval(intervalId.value);
		}, 1000);
	};

	onBeforeUnmount(() => {
		clearInterval(intervalId.value);
	});

	return {
		showTimeToast
	};
};
export default usePrizeDropsToasts;
