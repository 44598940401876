import type { ExtractFromAPI, v1 } from "@netgame/openapi";

import type { AsyncDataOptions } from "./useAsync";
import useAsyncFetch from "./useAsyncFetch";
import useGamesState from "./useGamesState";

type QuestsPageResponse = Required<ExtractFromAPI<v1.paths, "/rest/page/quests/", "get">>;
type Days = NonNullable<QuestsPageResponse["payload"]["playerData"]>["days"];
type Tasks = NonNullable<Days[number]>["tasks"];
type Task = NonNullable<Tasks[number]>;
// Remove games from tasks and add games ids
type TaskWithGamesIds = {
	[K in keyof Task]: K extends "action"
		? {
				[L in keyof Task[K]]: L extends "details"
					? { [M in keyof Task[K][L]]: M extends "games" ? Array<number> : Task[K][L][M] }
					: Task[K][L];
			}
		: Task[K];
};

const removeGamesFromTasks = (tasks: Tasks) =>
	tasks.map((task) => {
		if (task.action?.type === "gamesList") {
			const newTask: TaskWithGamesIds = {
				...task,
				action: {
					...task.action,
					details: {
						games: task.action.details?.games?.map(({ id }) => id)
					}
				}
			};
			return newTask;
		}
		return task;
	});

const removeGamesFromDays = (days: Days) =>
	days.map((day) => ({
		...day,
		tasks: removeGamesFromTasks(day.tasks)
	}));

const useGetPageQuestData = (options?: AsyncDataOptions<QuestsPageResponse> & { cached?: true }) => {
	const { select, add } = useGamesState();
	const asyncData = useAsyncFetch({
		path: "/rest/page/quests/",
		method: "get",
		options: {
			key: "quests",
			cached: options?.cached ?? true,
			server: options?.server,
			immediate: options?.immediate,
			watch: options?.watch,
			default: () => ({
				games: Array.from({ length: 16 }).map(() => ({ skeleton: true }))
			}),
			transform(data) {
				const days = data?.payload?.playerData?.days;
				const day = days?.find(({ isCompleted, isEnded, isLocked }) => !isCompleted && !isEnded && !isLocked);
				const task = day?.tasks?.find(({ isCompleted }) => !isCompleted);
				const games = task?.action?.details?.games;

				return {
					type: data.payload?.quest?.type,
					playerData: {
						...data.payload?.playerData,
						days: removeGamesFromDays(data.payload?.playerData?.days || [])
					},
					quest: data.payload?.quest,
					futureQuest: data.payload?.futureQuest,
					games: add(games || [])
				};
			}
		}
	});
	const games = computed(() => select(asyncData.data.value.games));
	const isSeasonQuest = computed(() => asyncData.data.value.type === "season");
	const totalTasksPrize = computed(() => {
		let totalEntries = 0;
		let totalCoins = 0;

		asyncData.data.value.playerData?.days.forEach((day) => {
			day.tasks.forEach((task) => {
				totalEntries += task.prize?.entries || 0;
				totalCoins += task.prize?.coins || 0;
			});
		});

		return { totalEntries, totalCoins };
	});
	return {
		...asyncData,
		totalTasksPrize,
		games,
		isSeasonQuest
	};
};

export default useGetPageQuestData;
