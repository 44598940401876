<script setup lang="ts">
const { t } = useT();
const { isMobile } = useDevice();

const handleOpenCash = () => {
	window?.$store?.gaCash?.deposit?.({
		location: "header",
		step: "view_payments_info"
	});
	window?.$cash?.$router?.push?.("/cash/deposit-by-money/");
};
</script>

<template>
	<header data-tid="header">
		<div class="left">
			<NuxtLink to="/" class="logo" data-tid="logo">
				<NuxtImg
					src="nuxt-img/logo-header@2x.png"
					format="webp"
					:width="isMobile ? '120' : '188'"
					:height="isMobile ? '80' : '128'"
					alt="logo"
					loading="lazy"
				/>
			</NuxtLink>
			<NuxtLink to="/issues/all-games/" active-class="active" class="header-link" data-tid="header-games">
				<NuxtImg src="nuxt-img/header/games.png" format="webp" width="24" height="24" alt="games" loading="lazy" />
				<AText as="div" class="text" :modifiers="['semibold']">{{ t("Games") }}</AText>
			</NuxtLink>
			<NuxtLink to="/issues/jackpot/" active-class="active" class="header-link" data-tid="header-jackpot">
				<NuxtImg src="nuxt-img/header/jackpot.png" format="webp" width="24" height="24" alt="jackpot" loading="lazy" />
				<AText as="div" class="text" :modifiers="['semibold']">{{ t("Jackpot") }}</AText>
			</NuxtLink>
		</div>
		<div class="center">
			<LazyOHeaderRewards location-tid="desk" />
		</div>
		<div class="right">
			<ClientOnly>
				<LazyONotificationCenterBox class="notification-center in-menu" />
			</ClientOnly>
			<NuxtLink to="/account/" class="avatar" data-tid="header-avatar">
				<NuxtImg src="nuxt-img/avatar.png" format="webp" width="96" height="96" alt="avatar" loading="lazy" />
			</NuxtLink>
			<OHeaderBalanceSwitcher />
			<AButton variant="primary" class="cash-btn" size="lg" data-tid="header-buy-btn" @click="handleOpenCash">
				<ASvg class="cash-icon" name="header/cash" filled />
				<AText v-if="!isMobile" variant="toledo" :modifiers="['medium']">{{ t("Buy") }}</AText>
			</AButton>
		</div>
	</header>
</template>

<style scoped lang="scss">
header {
	background: var(--theme-dark);
	padding: 10px 16px 10px 12px;
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 16px;
	border-bottom: 1px solid var(--neutral-800);
	@include media-breakpoint-up(lg) {
		padding: 16px 32px;
		height: 80px;
	}
	@include media-breakpoint-down(lg) {
		.center {
			order: 2;
		}
	}
}
.icon {
	font-size: 64px;
}
.right {
	display: flex;
	align-items: center;
	gap: 8px;
	@include media-breakpoint-down(lg) {
		width: 100%;
		justify-content: center;
	}
	@include media-breakpoint-up(lg) {
		gap: 16px;
	}
}
.avatar {
	display: none;

	img {
		width: 48px;
		height: 48px;
	}

	@include media-breakpoint-up(sm) {
		display: block;
	}
}

.cash-btn {
	--a-button-primary-box-shadow: 0 0 10px 0 rgba(249, 208, 26, 0.9);
	--a-button-default-padding: 8px;
	.cash-icon {
		font-size: 24px;
		@include media-breakpoint-up(lg) {
			margin-right: 4px;
		}
	}
}

.header-link {
	display: none;
	align-items: center;
	gap: 8px;
	color: var(--cannes);

	@include media-breakpoint-up(md) {
		display: flex;
	}

	&:hover {
		color: var(--colon);
	}

	&.active {
		color: var(--caracas);
	}

	.text {
		margin-top: 4px;
	}
}

.left {
	display: flex;
	align-items: center;
	gap: 16px;
}
@include media-breakpoint-up(lg) {
	.center {
		width: 100%;
	}
}
.logo {
	width: 95px;
	height: 64px;
	img {
		width: inherit;
		height: inherit;
	}
	@include media-breakpoint-down(lg) {
		width: 60px;
		height: 40px;
	}
	@media screen and (max-width: 370px) {
		background: url("/nuxt-img/logo-header-m.png") no-repeat center center / contain;
		width: 40px;
		height: 40px;
		img {
			display: none;
		}
	}
}
</style>
